import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import hitTracker from './hittracker';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import LoadingScreen from "./LoadingScreen";

hitTracker();
const theme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    }
  },
  palette: {
    primary: {
      light: '#335bb3',
      main: '#0033a1',
      dark: '#002370',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#33afe5',
      main: '#009BDF',
      dark: '#006c9c',
      contrastText: '#ffffff',
    },
    text: {
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      primary: "rgba(0, 0, 0, 0.9)",
      secondary: "rgba(0, 0, 0,.75)",
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      '"HelveticaNeueW01-65Medi"',
      'Arial',
      'sans-serif',
    ].join(','),
  }
});
ReactDOM.render(<MuiThemeProvider theme={theme}>
    <Suspense fallback={<LoadingScreen/>}>
      <Router>
        <Switch>
          <Redirect
            exact
            from="/"
            to="/licensingquestionnaire"
          />
          <Route
            exact
            path="/licensingquestionnaire"
            component={lazy(() => import('./eq/App'))}
          />
          <Route
            exact
            path="/simulatorlicensingquestionnaire"
            component={lazy(() => import('./simulator/App'))}
          />
        </Switch>
      </Router>
    </Suspense>
  </MuiThemeProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
